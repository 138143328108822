// ** Utils
import { http } from 'src/@core/utils/http';

// ** Config
import apiConfig from 'src/configs/api';

// ** Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Get Categories
export const getCategories = createAsyncThunk('helpers/getCategories', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getCategoriesEndpoint, {
      params: {
        page_size: 999,
      },
    });
    const data = response.data;

    return {
      categories: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Domains
export const getDomains = createAsyncThunk('helpers/getDomains', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getDomainsEndpoint, {
      params: {
        page_size: 999,
      },
    });
    const data = response.data;

    return {
      domains: data.results || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// ** Get Languages
export const getLanguages = createAsyncThunk('helpers/getLanguages', async (_params, { rejectWithValue }) => {
  try {
    const response = await http.get(apiConfig.getLanguagesEndpoint);
    const data = response.data;

    return {
      languages: data || [],
    };
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const helpersSlice = createSlice({
  name: 'helpers',
  initialState: {
    isLoadingCategories: false,
    isLoadingDomains: false,
    isLoadingLanguages: false,
    categories: [],
    domains: [],
    languages: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.isLoadingCategories = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload.categories;

        state.isLoadingCategories = false;
      })
      .addCase(getCategories.rejected, (state) => {
        state.isLoadingCategories = false;
      });
    builder
      .addCase(getDomains.pending, (state) => {
        state.isLoadingDomains = true;
      })
      .addCase(getDomains.fulfilled, (state, action) => {
        state.domains = action.payload.domains;

        state.isLoadingDomains = false;
      })
      .addCase(getDomains.rejected, (state) => {
        state.isLoadingDomains = false;
      });
    builder
      .addCase(getLanguages.pending, (state) => {
        state.isLoadingLanguages = true;
      })
      .addCase(getLanguages.fulfilled, (state, action) => {
        state.languages = action.payload.languages;

        state.isLoadingLanguages = false;
      })
      .addCase(getLanguages.rejected, (state) => {
        state.isLoadingLanguages = false;
      });
  },
});

export default helpersSlice.reducer;
