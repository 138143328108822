// ** React
import { createContext, useEffect, useState } from 'react';

// ** Components
import Spinner from 'src/@core/components/Spinner';

// ** Http
import { http } from 'src/@core/utils/http';

// ** Config
import authConfig from 'src/configs/auth';

// ** Utils
import localStorageService from 'src/@core/utils/local-storage';

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  config: {
    domain: null,
    language: null,
  },
  configModal: false,
  setUser: () => null,
  setConfig: () => null,
  restoreConfig: () => null,
  openConfigModal: () => null,
  closeConfigModal: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
};

const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }) => {
  // ** States
  const [loading, setLoading] = useState(defaultProvider.loading);
  const [user, setUser] = useState(defaultProvider.user);
  const [config, setConfig] = useState(defaultProvider.config);
  const [configModal, setConfigModal] = useState(defaultProvider.configModal);

  // ** Effects
  useEffect(() => {
    const initAuth = async () => {
      const storedToken = localStorageService.get('accessToken');
      if (storedToken) {
        setLoading(true);

        await http
          .get(authConfig.meEndpoint)
          .then(async (response) => {
            setUser({ ...response.data });
            setLoading(false);
          })
          .catch(() => {
            handleLogout();
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    };
    initAuth();
  }, []);

  // ** Handlers
  const handleLogin = (params, errorCallback) => {
    http
      .post(authConfig.loginEndpoint, params)
      .then(async (res) => {
        localStorageService.set('accessToken', res.data.access);
        localStorageService.set('refreshToken', res.data.refresh);
      })
      .then(() => {
        http.get(authConfig.meEndpoint).then(async (response) => {
          setUser({ ...response.data });
        });
      })
      .catch((err) => {
        const error = err;
        errorCallback ? errorCallback(error.response?.data) : null;
      });
  };

  const handleLogout = () => {
    setUser(null);

    localStorageService.remove('accessToken');
    localStorageService.remove('refreshToken');
  };

  const handleOpenConfigModal = () => setConfigModal(true);
  const handleCloseConfigModal = () => setConfigModal(false);

  const handleRestoreConfig = () => {
    const storedLanguage = localStorageService.get('language') || 'ru';
    const storedDomain = localStorageService.get('domain') || 'media5.com';

    setConfig({
      language: storedLanguage,
      domain: storedDomain,
    });
  };

  const handleSetConfig = (values) => {
    localStorageService.set('language', values.language);
    localStorageService.set('domain', values.domain);

    setConfig(values);
  };

  const values = {
    loading,
    user,
    config,
    configModal,
    setUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout,
    restoreConfig: handleRestoreConfig,
    setConfig: handleSetConfig,
    openConfigModal: handleOpenConfigModal,
    closeConfigModal: handleCloseConfigModal,
  };

  if (loading) return <Spinner />;

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
