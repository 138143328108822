// ** Axios
import axios from 'axios';

// ** Config
import apiConfig from 'src/configs/api';
import authConfig from 'src/configs/auth';

// ** Utils
import localStorageService from 'src/@core/utils/local-storage';
import eventBus from 'src/@core/utils/event-bus';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
};

const http = axios.create({
  baseURL: apiConfig.apiBaseUrl,
  timeout: 5000,
  headers,
});

const handleRefresh = async (params) => {
  const response = await http.post(authConfig.refreshEndpoint, {
    refresh: params.refresh,
  });

  const data = response.data;
  if (data.access) {
    localStorageService.set('accessToken', data.access);
  }

  return Promise.resolve();
};

const httpInterceptor = () => {
  http.interceptors.request.use(
    async (config) => {
      const accessToken = localStorageService.get('accessToken');
      const language = localStorageService.get('language') || 'ru';
      const domain = localStorageService.get('domain') || 'media5.com';

      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      config.headers['Accept-Language'] = language;
      config.headers['X-Domain'] = domain;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  http.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      let originalRequest = error.config;

      if (error.response && error.response.status === 401 && !originalRequest.url.includes('/auth/token/refresh/')) {
        if (!originalRequest.__retry) {
          originalRequest.__retry = true;
          const refreshToken = localStorageService.get('refreshToken');

          if (refreshToken) {
            try {
              await handleRefresh({ refresh: refreshToken });
              return http.request(originalRequest);
            } catch (e) {
              eventBus.publish('auth:logout');
              return Promise.reject(error);
            }
          } else {
            eventBus.publish('auth:logout');
          }
        }
      }

      return Promise.reject(error);
    },
  );
};

export { http, httpInterceptor };
