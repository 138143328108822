export default {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://api.media5.com/',
  getPagesEndpoint: '/pages/',
  getPageEndpoint: '/pages/:name/',
  editPageEndpoint: '/pages/:name/',
  deletePageEndpoint: '/pages/:name/',
  createPageEndpoint: '/pages/',
  setPagesSortEndpoint: '/pages/sort/',
  getPageHistoryEndpoint: '/pages/:name/history/',
  getDiplomasEndpoint: '/diplomas/',
  getDiplomaEndpoint: '/diplomas/:id/',
  editDiplomaEndpoint: '/diplomas/:id/',
  deleteDiplomaEndpoint: '/diplomas/:id/',
  createDiplomaEndpoint: '/diplomas/',
  getCategoriesEndpoint: '/categories/',
  getCategoryEndpoint: '/categories/:id/',
  editCategoryEndpoint: '/categories/:id/',
  deleteCategoryEndpoint: '/categories/:id/',
  createCategoryEndpoint: '/categories/',
  photoUploadEndpoint: '/documents/upload/',
  getDomainsEndpoint: '/domains/',
  getLanguagesEndpoint: '/languages/',
};
