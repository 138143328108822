// ** Toolkit
import { configureStore } from '@reduxjs/toolkit';

// ** Reducers
import pages from './pages';
import diplomas from './diplomas';
import categories from './categories';
import helpers from './helpers';

export const store = configureStore({
  reducer: {
    pages,
    diplomas,
    categories,
    helpers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
